export const CREATE_POST_ACTIONS = {
  START: 'CREATE_POST_START',
  SUCCESS: 'CREATE_POST_SUCCESS',
  FAILED: 'CREATE_POST_FAILED',
};

export const GET_POST_ACTIONS = {
  START: 'GET_POST_START',
  SUCCESS: 'GET_POST_SUCCESS',
  FAILED: 'GET_POST_FAILED',
};

export const GET_MORE_POST_ACTIONS = {
  START: 'GET_MORE_POST_START',
  SUCCESS: 'GET_MORE_POST_SUCCESS',
  FAILED: 'GET_MORE_POST_FAILED',
};
