export const LOGIN_ACTIONS = {
  START: 'LOGIN_START',
  SUCCESS: 'LOGIN_SUCCESS',
  FAILED: 'LOGIN_FAILED',
};

export const LOGOUT_ACTIONS = {
  SUCCESS: 'LOGOUT_SUCCESS',
};

export const SIGNUP_ACTIONS = {
  START: 'SIGNUP_START',
  SUCCESS: 'SIGNUP_SUCCESS',
  FAILED: 'SIGNUP_FAILED',
};

export const VERIFY_TOKEN_ACTIONS = {
  START: 'VERIFY_TOKEN_START',
  SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  FAILED: 'VERIFY_TOKEN_FAILED',
};
